import React from 'react';

const Hero = () => {
  return (
    <section className="bg-gray-100 py-16 px-4">
      <div className="container mx-auto flex flex-col md:flex-row items-center">
        <img src={`${process.env.PUBLIC_URL}/img/retroVanNew.jpg`} alt="Hero" className="w-full md:w-1/2 mb-8 md:mb-0 md:mr-8"/>
        <div className="text-center md:text-left md:flex-1">
          <p className="text-base font-semibold text-gray-800 mb-4">No more complicated integrations to access travel data</p>
          <h1 className="text-4xl font-bold mb-4">Effortlessly build and scale travel applications</h1>
          <p className="mb-8 text-lg max-w-2xl">through a unified high-performance API coupled with our customizable drag-and-drop components powered with AI.</p>
          <div className="flex flex-col md:flex-row items-center md:space-x-4">
            <button className="bg-[#D96236] text-black py-2 px-4 rounded mb-4 md:mb-0 hover:bg-[#C5512B]">Try for free</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
