import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-white py-6">
      <div className="container mx-auto px-6 flex flex-col md:flex-row justify-between items-center">
        <div className="flex items-center space-x-2 mb-4 md:mb-0">
          <img src={`${process.env.PUBLIC_URL}/img/logoTriptude.png`} alt="Triptude Logo" className="h-10 w-10 rounded-full object-cover" />
          <div className="text-lg">Triptude © 2024 All rights reserved.</div>
        </div>
        <nav>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
