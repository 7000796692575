import React from 'react';

const Integration = () => {
  return (
    <section className="bg-white py-16 px-4">
      <div className="container mx-auto flex flex-col md:flex-row items-center">
        <div className="text-center md:text-left md:flex-1 mb-8 md:mb-0">
          <p className="text-base font-semibold text-gray-800 mb-4">Unique technology</p>
          <h2 className="text-3xl font-bold mb-4">Seamless integration and customizable components</h2>
          <p className="mb-4 text-lg">Our API and compontents are crafted with cutting-edge technology that allows for seamless integration with existing systems.</p>
          <p className="mb-8 text-lg max-w-2xl mx-auto md:mx-0">Our customizable components embedded with our travel data are built to convert, and you can add them to your website in just a few clicks.</p>
          <button className="bg-[#6FA68C] text-black py-2 px-4 rounded hover:bg-[#5E8F77] w-full md:w-auto">Try now</button>
        </div>
        <img src={`${process.env.PUBLIC_URL}/img/retroAstroNew.jpg`} alt="Retro Car" className="w-full md:w-1/2"/>
      </div>
    </section>
  );
};

export default Integration;
