import React from 'react';

const UseCases = () => {
  return (
    <section className="relative py-16 text-center bg-[#6FA68C]">
      <div className="container mx-auto px-6">
        <img src={`${process.env.PUBLIC_URL}/img/retroUsecases.png`} alt="Retro Usecases" className="mx-auto mb-8 w-full max-w-md"/>
        <h2 className="text-3xl font-bold mb-4 text-black">Use cases</h2>
        <p className="mb-4 text-lg text-black">Examples of how you can apply our technology:</p>
        <div className="mb-4 text-left max-w-2xl mx-auto">
          <p className="mb-2 text-black"><span className="font-bold">Large scale enterprises:</span> Leverage detailed data to optimize travel planning and cost planning, tap into seamless and efficient booking processes.</p>
          <hr className="border-t border-white mb-4"/>
          <p className="mb-2 text-black"><span className="font-bold">Hospitality operators:</span> Integrate inventory of the entire customer journey and offer complete travel solutions and improve booking conversion by providing diverse and personalized travel options.</p>
          <hr className="border-t border-white mb-4"/>
          <p className="mb-2 text-black"><span className="font-bold">Entrepreneurs:</span> Monetize your platform by offering comprehensive travel services and earn commissions on bookings.</p>
        </div>
        <button className="bg-[#D96236] text-black py-2 px-4 rounded hover:bg-[#C5512B] w-full md:w-auto mb-16">Sign up now</button>
      </div>
      <div className="absolute inset-x-0 bottom-0 h-24 bg-[#F5F5DC]"></div>
    </section>
  );
};

export default UseCases;
