import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Feature from './components/Feature';
import Integration from './components/Integration';
import AI from './components/AI';
import UseCases from './components/UseCases';
import Testimonials from './components/Testimonials';
import JoinUs from './components/JoinUs';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Header id="home" />
      <Hero />
      <Feature />
      <Integration />
      <AI />
      <UseCases />
      <Testimonials />
      <JoinUs id="joinus" />
      <Footer />
    </div>
  );
}

export default App;
