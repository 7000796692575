import React from 'react';

const AI = () => {
  return (
    <section className="bg-white py-16 text-center">
      <div className="container mx-auto px-6">
        <p className="text-base font-semibold text-gray-800 mb-4 text-left max-w-2xl mx-auto">Artificial Intelligence</p>
        <h2 className="text-3xl font-bold mb-4">Revolutionizing Travel data with AI</h2>
        <p className="mb-8 text-lg max-w-2xl mx-auto">Our integrated large language models will help you build effortlessly:</p>
        <ul className="list-none text-left max-w-2xl mx-auto mb-8">
          <li><span className="font-bold">Personalized recommendations</span> - Offer tailored travel options to your users.</li>
          <li><span className="font-bold">Dynamic pricing</span> - Optimize revenue with intelligent pricing models.</li>
          <li><span className="font-bold">Demand forecasting</span> - Predict travel trends and manage inventory effectively.</li>
          <li><span className="font-bold">Sentiment analysis</span> - Understand customer feedback to improve service quality.</li>
          <li><span className="font-bold">Content generation</span> - Generate high-quality marketing content effortlessly.</li>
          <li><span className="font-bold">Intelligent Chatbots</span> - Enhance customer service with AI-powered chatbots.</li>
        </ul>
        <button className="bg-[#6FA68C] text-black py-2 px-4 rounded hover:bg-[#5E8F77] w-full md:w-auto">Sign up now</button>
      </div>
    </section>
  );
};

export default AI;
