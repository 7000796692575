import React from 'react';

const Testimonials = () => {
  return (
    <section className="bg-white py-16 text-center">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold mb-4">Testimonials</h2>
        <div className="bg-[#F5F5DC] p-6 rounded-lg shadow-md mb-8 max-w-lg mx-auto">
          <p className="text-lg font-bold mb-4">"Using this API, we’ve expanded our travel offerings significantly, leading to a 25% increase in bookings."</p>
          <div className="flex justify-center mb-4">
            <span className="text-black">★★★★★</span>
          </div>
          <p className="font-bold">- Louise Audo, Product Manager, Hotelplan Group</p>
        </div>
        <div className="flex justify-center mb-8">
          <span className="h-2 w-2 bg-gray-400 rounded-full inline-block mx-1"></span>
          <span className="h-2 w-2 bg-gray-400 rounded-full inline-block mx-1"></span>
          <span className="h-2 w-2 bg-gray-400 rounded-full inline-block mx-1"></span>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
