import React from 'react';

const Features = () => {
  return (
    <section className="relative py-16 text-center">
      <div className="relative py-8">
        <div className="container mx-auto px-6">
          <p className="text-lg font-semibold mb-8 text-black">Built together with and trusted by leading travel and technology companies</p>
          <div className="flex flex-wrap justify-center space-y-8 md:space-y-0 md:space-x-8 mb-8">
            <img src={`${process.env.PUBLIC_URL}/img/hotelplanLogo.png`} alt="Hotelplan" className="h-12 max-w-xs mx-auto"/>
            <img src={`${process.env.PUBLIC_URL}/img/sonyLogo.png`} alt="Sony" className="h-12 max-w-xs mx-auto"/>
          </div>
        </div>
      </div>
      <div className="relative bg-[#6FA68C] py-16">
        <div className="container mx-auto px-6 flex flex-col md:flex-row items-center">
          <img src={`${process.env.PUBLIC_URL}/img/bagsUnlock.png`} alt="Bags" className="w-full md:w-1/2 mb-8 md:mb-0 md:mr-8"/>
          <div className="text-center md:text-left md:flex-1">
            <h2 className="text-3xl font-bold mb-4 text-black">Unlock the full potential of travel data</h2>
            <p className="mb-8 text-lg text-black">Our Travel API connects you to an extensive range of hotels, vacation homes, transportation options, tours, and activities and we have integrated AI to our platform so that you dont have to.</p>
            <button className="bg-[#D96236] text-black py-2 px-4 rounded hover:bg-[#C5512B] mb-8 md:mb-0 w-full md:w-auto">Join waiting list</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
