import React from 'react';

const Header = () => {
  return (
    <header className="bg-white shadow-md py-4">
      <div className="container mx-auto flex justify-between items-center px-6">
        <div className="flex items-center space-x-2">
          <img src={`${process.env.PUBLIC_URL}/img/logoTriptude.png`} alt="Triptude Logo" className="h-10 w-10 rounded-full object-cover" />
          <div className="text-lg font-bold">Triptude</div>
        </div>
      </div>
    </header>
  );
};

export default Header;
