import React from 'react';

const JoinUs = () => {
  return (
    <section className="relative py-16 text-center bg-[#6FA68C]">
      <div className="container mx-auto px-6">
        <img src={`${process.env.PUBLIC_URL}/img/contactusPhoneYellow.png`} alt="Contact Us Phone" className="mx-auto mb-8 w-full max-w-md"/>
        <h2 className="text-3xl font-bold mb-4 text-black">Join us today</h2>
        <p className="mb-8 text-lg text-black max-w-2xl mx-auto">
          Sign up to access our comprehensive travel inventory and customizable components.
        </p>
        <button className="bg-[#D96236] text-black py-2 px-4 rounded hover:bg-[#C5512B] w-full max-w-md mx-auto mb-16">Sign up now</button>
      </div>
      <div className="absolute inset-x-0 bottom-0 h-24 bg-[#F5F5DC]"></div>
    </section>
  );
};

export default JoinUs;
